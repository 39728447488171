import React, { useEffect, useRef } from 'react'

import { Editor } from '@tinymce/tinymce-react'

import Input from 'unno-comutils/form/Input'
import { IconDelete } from 'unno-comutils/Icon'
import Button from 'unno-comutils/Button'
import List, { ListBody, ListContainer, ListHead } from 'unno-comutils/List'
import { FormContainer } from 'unno-comutils/form/Form'
import { MockEmpty } from './common'

const DEFAUTL_BANK = { bank: '', branch: '', number: '', account: '' }

export function TableBank (props: any) {
    const { datas } = props

    const onChange = (update: any, index: any) => props.onChange(datas.map((d: any, x: any) => x === index ? { ...d, ...update } : d))

    const onAdd = () => {
        if (Array.isArray(datas))
            props.onChange([...datas, { ...DEFAUTL_BANK }])
    }

    const onDelete = (index: any) => props.onChange(datas.filter((_: any, x: any) => x !== index))

    return <FormContainer label={'ข้อมูลบัญชีธนาคาร'} pb>
        <ListContainer>
            <ListHead>
                <div className="w-icon c">#</div>
                <div className="w-40 c">ธนาคาร</div>
                <div className="w-40 c">สาขา</div>
                <div className="w-40 c">เลขที่บัญชี</div>
                <div className="w-fill">ชื่อ-นามสกุล</div>
                <div className="w-icon"/>
            </ListHead>
            <ListBody>
                {datas && Array.isArray(datas) && datas.length > 0 ? datas.map((item, x) => <List key={'item_' + item.key}>
                    <div className="w-icon c">{x + 1}</div>
                    <Input className="w-40 c" center value={item.bank} onChange={v => onChange({ bank: v }, x)}/>
                    <Input className="w-40 c" center value={item.branch} onChange={v => onChange({ branch: v }, x)}/>
                    <Input className="w-40 c" center value={item.number} onChange={v => onChange({ number: v }, x)}/>
                    <Input className="w-fill" value={item.account} onChange={v => onChange({ account: v }, x)}/>
                    <IconDelete onClick={() => onDelete(x)}/>
                </List>) : <MockEmpty/>}
            </ListBody>
        </ListContainer>
        <Button m0 className="mt-2" sm success onClick={onAdd}>เพิ่มแถวใหม่</Button>
    </FormContainer>
}

export function HtmlEditor (props: { height?: number, value: any, onChange: (v: any) => void }) {
    const editorRef = useRef<any>(null)

    function onChange () {
        props.onChange(editorRef.current?.editor?.getContent())
    }

    useEffect(() => {
        if (editorRef.current.elementRef?.current) {
            if (!editorRef.current.elementRef?.current?.classList?.contains('document-content-html'))
                editorRef.current.elementRef?.current?.classList?.add('document-content-html')
        }
    }, [])

    return <Editor
        ref={editorRef}
        initialValue={props.value}
        init={{
            height: props.height || 500,
            menubar: false,
            //inline: true,
            automatic_uploads: true,
            images_upload_url: process.env.REACT_APP_BACKEND + 'app/upload_tiny_image',
            codesample_global_prismjs: true,
            plugins: 'table lists link visualblocks image',
            /*
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            */
            toolbar: 'formatselect | bold italic backcolor alignleft aligncenter alignright alignjustify | bullist numlist | link codesample table image | visualblocks removeformat',
            //skin_url: '/assets/libs/tinymce/skins/ui/oxide', // Static files path(step 2)
            //content_css: '/assets/libs/tinymce/skins/content/default/content.min.css'  // Static files path(step 2)
        }}
        onBlur={onChange}
    />
    // <div ref={editorRef} className="text-content">{props.value.content || ''}</div>
    //<ReactQuill theme="snow" value={value} onChange={v => onChange(v)}/></div>
}