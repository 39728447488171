import React from 'react'
// @ts-ignore
import ReactDOM from 'react-dom/client'

import App from './app'

declare global {
    interface Window {
        MEATA: any;
    }
}

const root = document.getElementById('root')
if (root) ReactDOM.createRoot(root).render(<App/>)
