import React from 'react'
// @ts-ignore
import * as MdIt from 'markdown-it'
// @ts-ignore
import MdIt_dl from 'markdown-it-deflist'

import { clsNames } from 'unno-comutils'

export function AlertPing (props: { className?: string }) {
    return <div className={clsNames('un-alert-dot', props.className)}>
        <div className="_ping"/>
        <div className="_dot"/>
    </div>
}

export function Markdown (props: any) {
    const html = MdIt({ linkify: true }).use(MdIt_dl).render(props.children || '')
    return <div className={clsNames('text-content', props.className)} dangerouslySetInnerHTML={{ __html: html }}/>
}

export function TagValue (props: { name: string, value: string, color?: string }) {
    return <div className={'un-tag -value ' + (props.color ? ' -' + props.color : '-red')}>
        <span className="_n">{props.name}</span>
        <span className="_v">{props.value}</span>
    </div>
}

export function MockEmpty (props: { children?: any }) {
    return <div className="mock">{props.children || 'ยังไม่มีข้อมูล'}</div>
}
