import React from 'react'
import L from '@loadable/component'

import { Redirect, Route, Switch } from 'react-router-dom'

import { RouteNonuser, RoutePrivate } from 'unno-comutils/Route'
import Wait from 'unno-comutils/Wait'

const fallback = <Wait icon={'fan'}/>

const Login = L(() => import(/* webpackChunkName: "login" */'../login'), { fallback })

const Home = L(() => import(/* webpackChunkName: "home" */'../pages/home'), { fallback })
const PrintDocprint = L(() => import(/* webpackChunkName: "print.router" */'../print/docprint'), { fallback })

const Chat = L(() => import(/* webpackChunkName: "chat" */'../pages/chat'), { fallback })
const Chat2 = L(() => import(/* webpackChunkName: "chat2" */'../pages/chat2'), { fallback })
const Landing = L(() => import(/* webpackChunkName: "induct" */'../pages/landing'), { fallback })
const Web = L(() => import(/* webpackChunkName: "web" */'../pages/web'), { fallback })
const Call = L(() => import(/* webpackChunkName: "web" */'../pages/call'), { fallback })

const SearchInsurance = L(() => import(/* webpackChunkName: "search" */'../pages/search/insurance'), { fallback })

const Support = L(() => import(/* webpackChunkName: "chat" */'../pages/support'), { fallback })

const AdminHome = L(() => import(/* webpackChunkName: "admin.home" */'../pages/admin/home'), { fallback })
const AdminUser = L(() => import(/* webpackChunkName: "admin.user" */'../pages/admin/user'), { fallback })
const AdminChat = L(() => import(/* webpackChunkName: "admin.chat" */'../pages/admin/chat'), { fallback })
const AdminTicket = L(() => import(/* webpackChunkName: "admin.ticket" */'../pages/admin/ticket'), { fallback })
const AdminUserGroup = L(() => import(/* webpackChunkName: "admin.user.group" */'../pages/admin/userGroup'), { fallback })
const AdminLog = L(() => import(/* webpackChunkName: "admin.log" */'../pages/admin/log'), { fallback })
const AdminIn3rd = L(() => import(/* webpackChunkName: "admin.In3rd" */'../pages/admin/in3rd'), { fallback })
const AdminLab = L(() => import(/* webpackChunkName: "admin.lab" */'../pages/lab/_main'), { fallback })

const Customer = L(() => import(/* webpackChunkName: "setting.customer" */'../pages/customer'), { fallback })

const BookWait = L(() => import(/* webpackChunkName: "book.wait" */'../pages/book/wait'), { fallback })
const Revoke = L(() => import(/* webpackChunkName: "book.wait" */'../pages/book/revoke'), { fallback })

const BookExpress = L(() => import(/* webpackChunkName: "book.express" */'../pages/book/express'), { fallback })
const Book = L(() => import(/* webpackChunkName: "book.data" */'../pages/book/data'), { fallback })
const BookFollow = L(() => import(/* webpackChunkName: "book.follow" */'../pages/book/follow'), { fallback })
const BookRevoke = L(() => import(/* webpackChunkName: "book.revoke" */'../pages/book/old/revoke'), { fallback })
const SendDoc = L(() => import(/* webpackChunkName: "book.sendDoc" */'../pages/book/sendDoc'), { fallback })
const DocNotify = L(() => import(/* webpackChunkName: "book.notify" */'../pages/book/notify'), { fallback })

// ----- documnet

const DocumentConSole = L(() => import(/* webpackChunkName: "book.data_new" */'../pages/document'), { fallback })

// -----

const AccountCustomer = L(() => import(/* webpackChunkName: "account.customer" */'../pages/account/customer'), { fallback })
const AccountCustomerOther = L(() => import(/* webpackChunkName: "account.customerOther" */'../pages/account/customerOther'), { fallback })
const AccountCustomerSummary = L(() => import(/* webpackChunkName: "account.customerSummary" */'../pages/account/customerSummary'), { fallback })
const AccountCustomerSlip = L(() => import(/* webpackChunkName: "account.customerSlip" */'../pages/account/customerSlip'), { fallback })
const AccountCustomerNotify = L(() => import(/* webpackChunkName: "account.customerNotify" */'../pages/account/customerNotify'), { fallback })
const AccountCustomerNotifyNew = L(() => import(/* webpackChunkName: "account.customerNotifyNew" */'../pages/account/customerNotifyNew'), { fallback })

const AccountCompany = L(() => import(/* webpackChunkName: "account.company" */'../pages/account/company'), { fallback })

const AccountCompanyWait = L(() => import(/* webpackChunkName: "account.companyWait" */'../pages/account/companyWait'), { fallback })
const AccountEmployee = L(() => import(/* webpackChunkName: "account.employee" */'../pages/account/employee'), { fallback })
const AccountAgent = L(() => import(/* webpackChunkName: "account.agent" */'../pages/account/agent'), { fallback })

const FinanceOfficeCost = L(() => import(/* webpackChunkName: "finance.office_cost" */'../pages/finance/officeCost'), { fallback })

const SettingBroker = L(() => import(/* webpackChunkName: "setting.broker" */'../pages/setting/broker'), { fallback })
const SettingCompany = L(() => import(/* webpackChunkName: "setting.company" */'../pages/setting/company'), { fallback })
const SettingAgent = L(() => import(/* webpackChunkName: "setting.agent" */'../pages/setting/agent'), { fallback })

const SettingEmployee = L(() => import(/* webpackChunkName: "setting.employee" */'../pages/setting/employee'), { fallback })
const SettingChat = L(() => import(/* webpackChunkName: "setting.chat" */'../pages/setting/chat'), { fallback })
const SettingBasic = L(() => import(/* webpackChunkName: "setting.basic" */'../pages/setting/basic'), { fallback })
const SettingMeta = L(() => import(/* webpackChunkName: "setting.meta" */'../pages/setting/meta/_main'), { fallback })
const SettingRevenue = L(() => import(/* webpackChunkName: "setting.revenue" */'../pages/setting/revenue'), { fallback })
const SettingNotice = L(() => import(/* webpackChunkName: "setting.notice" */'../pages/setting/notice'), { fallback })
const SettingAddress = L(() => import(/* webpackChunkName: "setting.address" */'../pages/setting/address'), { fallback })
const SettingDocPrint = L(() => import(/* webpackChunkName: "setting.docprint" */'../pages/setting/docprint'), { fallback })

const ToolsPaycompRecheck = L(() => import(/* webpackChunkName: "tools.paycompRecheck" */'../pages/tools/paycompRecheck'), { fallback })

// ----- REPORT

const Report = L(() => import(/* webpackChunkName: "report" */'../pages/report'), { fallback })

const ReportSaleSummary = L(() => import(/* webpackChunkName: "report.sale_summary" */'../report/saleSummary'), { fallback })
const ReportSaleCompany = L(() => import(/* webpackChunkName: "report.sale_company" */'../report/saleCompany'), { fallback })
const ReportSaleInsurance = L(() => import(/* webpackChunkName: "report.sale_insurance" */'../report/saleInsurance'), { fallback })

const ReportVehicleTop = L(() => import(/* webpackChunkName: "report.vehicle_top" */'../report/vehicleTop'), { fallback })

const ReportDocumentRevoke2 = L(() => import(/* webpackChunkName: "report.document_revoke2" */'../report/documentRevoke2'), { fallback })
const ReportDocumentRevoke3 = L(() => import(/* webpackChunkName: "report.document_revoke3" */'../report/documentRevoke3'), { fallback })
const ReportDocumentRevoke4 = L(() => import(/* webpackChunkName: "report.document_revoke4" */'../report/documentRevoke4'), { fallback })

const ReportCustomerBehavior = L(() => import(/* webpackChunkName: "report.customer_behavior" */'../report/customerBehavior'), { fallback })

const ReportInsurance = L(() => import(/* webpackChunkName: "report.insurance" */'../report/insurance'), { fallback })
const ReportInsuranceBook = L(() => import(/* webpackChunkName: "report.insurance_book" */'../report/insuranceBook'), { fallback })
const ReportInsuranceList = L(() => import(/* webpackChunkName: "report.insurance_list" */'../report/insuranceList'), { fallback })

const ReportIncomeDaily = L(() => import(/* webpackChunkName: "report.income_daily" */'../report/incomeDaily'), { fallback })
const ReportIncomeCredit = L(() => import(/* webpackChunkName: "report.chat" */'../report/incomeCredit'), { fallback })
const ReportIncomePerformance = L(() => import(/* webpackChunkName: "report.chat" */'../report/incomePerformance'), { fallback })

const ReportOutcome = L(() => import(/* webpackChunkName: "report.chat" */'../report/outcome'), { fallback })

const ReportChat = L(() => import(/* webpackChunkName: "report.chat" */'../report/chat'), { fallback })
const ReportSearch = L(() => import(/* webpackChunkName: "report.search" */'../report/search'), { fallback })
const ReportIoSummary = L(() => import(/* webpackChunkName: "report.io_summary" */'../report/ioSummary'), { fallback })

const ReportCompanySummary = L(() => import(/* webpackChunkName: "report.company_summary" */'../report/companySummary'), { fallback })
const ReportInsuranceSummary = L(() => import(/* webpackChunkName: "report.insurance_summary" */'../report/insuranceSummary'), { fallback })
const ReportInsuranceSearch = L(() => import(/* webpackChunkName: "report.insurance_summary" */'../report/insuranceSearch'), { fallback })

export default function Routing () {
    return <Switch>
        <Route exact path="/"><Redirect to="/home"/></Route>

        <RouteNonuser exact path="/login" children={<Login/>}/>
        <RoutePrivate exact path="/home" children={<Home/>}/>
        <RoutePrivate exact path="/support" children={<Support/>}/>
        <RoutePrivate exact path="/print/docprint" children={<PrintDocprint/>}/>

        <RoutePrivate exact path={['/web', '/web/:page']} children={<Web/>}/>

        <RoutePrivate exact path="/call" children={<Call/>}/>
        <RoutePrivate exact path={['/landing', '/landing/:page']} children={<Landing/>}/>

        <RoutePrivate exact path="/insurance" children={<SearchInsurance/>}/>

        <RoutePrivate needRole={'chat'} exact path={['/chat2', '/chat2/:id']} children={<Chat2/>}/>
        <RoutePrivate needRole={'chat'} exact path={['/chat', '/chat/:id']} children={<Chat/>}/>

        <RoutePrivate needRole={'config.customer'} exact path={['/customer', '/customer/:page']} children={<Customer/>}/>

        <RoutePrivate needRole={'book.wait'} exact path="/book_order" children={<BookWait/>}/>
        <RoutePrivate needRole={'book.revoke'} exact path="/revoke" children={<Revoke/>}/>{/*book.revoke*/}

        <RoutePrivate needRole={'book.express'} exact path="/book/express" children={<BookExpress/>}/>
        <RoutePrivate needRole={'book.day'} exact path="/book/data" children={<Book/>}/>
        <RoutePrivate needRole={'book.follow'} exact path="/book/follow" children={<BookFollow/>}/>
        <RoutePrivate needRole={'book.revoke'} exact path="/book/revoke" children={<BookRevoke/>}/>
        <RoutePrivate needRole={'book.send-doc'} exact path="/book/send_doc" children={<SendDoc/>}/>
        <RoutePrivate needRole={'book.day'} exact path="/book/notify" children={<DocNotify/>}/>

        <RoutePrivate exact path={['/document', '/document/:page', '/document/:page/:id']} children={<DocumentConSole/>}/>

        <RoutePrivate needRole={'account.customer.data'} exact path="/account/customer/data" children={<AccountCustomer/>}/>
        <RoutePrivate needRole={'account.customer.other'} exact path="/account/customer/other" children={<AccountCustomerOther/>}/>
        <RoutePrivate needRole={'account.customer.summary'} exact path="/account/customer/summary" children={<AccountCustomerSummary/>}/>
        <RoutePrivate needRole={'account.customer.data'} exact path="/account/customer/slip" children={<AccountCustomerSlip/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/account/customer/notify_old" children={<AccountCustomerNotify/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/account/customer/notify" children={<AccountCustomerNotifyNew/>}/>

        <RoutePrivate needRole={'account.company'} exact path="/account/company/data" children={<AccountCompany/>}/>
        <RoutePrivate needRole={'account.company'} exact path="/account/company/wait" children={<AccountCompanyWait/>}/>
        <RoutePrivate needRole={'account.employee'} exact path="/account/employee" children={<AccountEmployee/>}/>
        <RoutePrivate needRole={'account.agent'} exact path="/account/agent" children={<AccountAgent/>}/>

        <RoutePrivate needRole={'finance.office_cost'} exact path="/finance/office_cost" children={<FinanceOfficeCost/>}/>

        <RoutePrivate needRole={'config.broker'} exact path="/setting/broker" children={<SettingBroker/>}/>
        <RoutePrivate needRole={'config.company'} exact path="/setting/company" children={<SettingCompany/>}/>
        <RoutePrivate needRole={'config.agent'} exact path="/setting/agent" children={<SettingAgent/>}/>
        <RoutePrivate needRole={'config.employee'} exact path="/setting/employee" children={<SettingEmployee/>}/>
        <RoutePrivate needRole={'config.revenue'} path="/setting/revenue" children={<SettingRevenue/>}/>
        <RoutePrivate needRole={'config.chat'} path="/setting/chat" children={<SettingChat/>}/>
        <RoutePrivate needRole={'config.meta'} path="/setting/meta" children={<SettingMeta/>}/>
        <RoutePrivate needRole={'config.basic'} exact path="/setting/basic" children={<SettingBasic/>}/>
        <RoutePrivate needRole={'config.notice'} exact path="/setting/notice" children={<SettingNotice/>}/>
        <RoutePrivate needRole={'config.address'} exact path="/setting/address" children={<SettingAddress/>}/>
        <RoutePrivate needRole={''} exact path={['/setting/docprint/:id', '/setting/docprint']} children={<SettingDocPrint/>}/>

        <RoutePrivate exact path="/tools/paycomp_recheck" children={<ToolsPaycompRecheck/>}/>

        <RoutePrivate needRole={'_report'} exact path="/report" children={<Report/>}/>

        <RoutePrivate needRole={'report.sale_summary'} exact path="/report/sale_summary" children={<ReportSaleSummary/>}/>
        <RoutePrivate needRole={'report.sale_company'} exact path="/report/sale_company" children={<ReportSaleCompany/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/report/sale_insurance" children={<ReportSaleInsurance/>}/>

        <RoutePrivate needRole={'report.vehicle_top'} exact path="/report/vehicle_top" children={<ReportVehicleTop/>}/>

        <RoutePrivate needRole={'report.document_revoke2'} exact path="/report/document_revoke2" children={<ReportDocumentRevoke2/>}/>
        <RoutePrivate needRole={'report.document_revoke3'} exact path="/report/document_revoke3" children={<ReportDocumentRevoke3/>}/>

        <RoutePrivate needRole={'report.chat'} exact path="/report/chat" children={<ReportChat/>}/>
        <RoutePrivate needRole={'report.document_revoke4'} exact path="/report/document_revoke4" children={<ReportDocumentRevoke4/>}/>

        <RoutePrivate needRole={'report.search'} exact path="/report/search" children={<ReportSearch/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/report/io_summary" children={<ReportIoSummary/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/report/company_summary" children={<ReportCompanySummary/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/report/income_daily" children={<ReportIncomeDaily/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/report/insurance_summary" children={<ReportInsuranceSummary/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/report/insurance_search" children={<ReportInsuranceSearch/>}/>

        <RoutePrivate needRole={'report.insurance_book'} exact path="/report/insurance_book" children={<ReportInsuranceBook/>}/>
        <RoutePrivate needRole={'report.insurance'} exact path="/report/insurance" children={<ReportInsurance/>}/>
        <RoutePrivate needRole={'report.insurance_list'} exact path="/report/insurance_list" children={<ReportInsuranceList/>}/>

        <RoutePrivate needRole={'report.income_credit'} exact path="/report/income_credit" children={<ReportIncomeCredit/>}/>
        <RoutePrivate needRole={'report.income_performance'} exact path="/report/income_performance" children={<ReportIncomePerformance/>}/>

        <RoutePrivate needRole={'report.outcome'} exact path="/report/outcome" children={<ReportOutcome/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/report/customer_behavior" children={<ReportCustomerBehavior/>}/>

        <RoutePrivate needRole={'__admin'} exact path="/admin/home" children={<AdminHome/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/admin/user" children={<AdminUser/>}/>
        <RoutePrivate needRole={'__admin'} path={['/admin/chat/:page', '/admin/chat']} children={<AdminChat/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/admin/user_group" children={<AdminUserGroup/>}/>
        <RoutePrivate needRole={'__admin'} exact path="/admin/log" children={<AdminLog/>}/>
        <RoutePrivate needRole={'__admin'} path="/admin/lab" children={<AdminLab/>}/>
        <RoutePrivate needRole={'__admin'} path={['/admin/ticket', '/admin/ticket/:page']} children={<AdminTicket/>}/>


        <RoutePrivate needRole={'__admin'} path={['/admin/in3rd/:page', '/admin/in3rd']} children={<AdminIn3rd/>}/>
    </Switch>
}
/*<RouterNonuser path="/*"><Redirect to="/login"/></RouterNonuser>*/
