import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { clsNames, date, dConfirm, dError, fromNow, get, linkClick, num, post, tSuccess, useAppContext, useUser, version } from 'unno-comutils'
import { Checkbox, FormContainer, Input, Photo } from 'unno-comutils/form'
import { Icon, Modal, PageScroll } from 'unno-comutils/ui'
import Dropdown from 'unno-comutils/Dropdown'
import { logout } from 'unno-comutils/connect'

import { AdminMenus, getSectionMenu, MainMenus, SettingMenus } from './service/menu'
import { TableBank } from './components/form'
import { ContactForm2 } from './components/contact'
import { addEvent, sendEvent } from './utils'
import { AlertPing } from './components/common'
import Tooltip from 'unno-comutils/Tooltip'

const page2url = (p: string) => {
    if (p.startsWith('/account/customer')) return 'account/customer/info'
    if (p.startsWith('/account/company')) return 'account/company/info'
    return null
}

export default function Layout (props: any) {
    const user = useUser()

    const [info, setInfo] = useState<any>(null)

    const location = useLocation()
    const history = useHistory()

    // ----- MEMO

    const cPath = useMemo(() => location.pathname + '/', [location])
    const sectionMenus = useMemo(() => getSectionMenu(cPath), [cPath])

    useEffect(() => {
        const url = page2url(cPath)
        if (url) {
            get(url).then(({ ok, info }) => {
                if (ok) setInfo(info)
            })
        }
    }, [cPath])

    const sectionInfo = useMemo(() => {
        if (!info) return null
        if (cPath.startsWith('/account/customer') && info.page === 'customer') {
            return <>
                ยอดค้างชำระ <strong className={'red mx-2'}>{num(info.money, 2)}</strong><span className="-nomin">บาท</span>
                <small className="ml-2">
                    ค่าเบี้ย <span className={'green mx-1'}>{num(info.money - info.moneyInterest - info.moneyFee, 2)}</span><span className="-nomin"> บาท ,</span>
                    ดอกเบี้ย <span className={'blue mx-1'}>{num(info.moneyInterest, 2)}</span><span className="-nomin"> บาท ,</span>
                    ค่าธรรมเนียม <span className={'orange ml-1'}>{num(info.moneyFee, 2)}</span><span className="-nomin"> บาท</span>
                </small>
                <span className={'small grey mx-2'}>({num(info.count, 0)})</span>
            </>
        }
        else if (cPath.startsWith('/account/company') && info.page === 'company') {
            return <>
                ยอดค้างชำระ <strong className={'red mx-2'}>{num(info.money, 2)}</strong>บาท
                <span className={'small grey mx-2'}>({num(info.count, 0)})</span>
            </>
        }
        return null
    }, [info, cPath])

    useEffect(() => {
        const cPath = location.pathname + '/'
        const sectionMenus = getSectionMenu(cPath)
        if (sectionMenus && !sectionMenus.some(m => location.pathname.startsWith(m.url))) {
            let m = sectionMenus.filter(m => !m.role || user.allow(m.role)).find((m: any) => m.main)
            if (!m) m = sectionMenus.find(m => !m.role || user.allow(m.role))
            if (m) history.push(m.url)
        }
    }, [location])

    const { layoutSide, layoutClass } = useMemo(() => {
        if (location.pathname.startsWith('/print/')) return { layoutSide: false, layoutClass: 'layout-print' }
        if (location.pathname === '/insurance') return { layoutSide: true, layoutClass: 'layout-insurance-search' }
        return { layoutSide: true, layoutClass: '' }
    }, [location])

    // ----- RENDER

    return <>
        {layoutSide && <SideMenu/>}
        <div className={clsNames('layout-content', layoutClass)}>
            {layoutSide && !!sectionMenus &&
                <div className="layout-section-menu">{sectionMenus
                    .filter(m => !m.role || user.allow(m.role))
                    .map(m => <a key={'m_' + m.page} href={m.url} onClick={e => linkClick(e, history)} className={'_item' + (cPath.startsWith(m.url + '/') ? ' -active' : '')}>
                        <Icon name={m.icon} solid={cPath.startsWith(m.url + '/')}/>
                        <span className="_title">{m.name}</span>
                    </a>)}
                    <div className="_info">{sectionInfo}</div>
                </div>}
            {props.children}
        </div>
    </>
}

function SideMenu () {
    const ctx = useAppContext()
    const user = useUser()

    const history = useHistory()

    const { pathname } = useLocation()

    const [profile, setProfile] = useState(false)

    const [miniSideMenu, setMiniSideMenu] = useState(localStorage.getItem('OBI_SIDEMENU_MINI') === '1')
    const [noFont, setNoFont] = useState<any>(localStorage.getItem('OBI_NO_FONT') === '1')

    // ----- ACTION

    const userLogout = () => {
        dConfirm('Logout ?').then(() => {
            logout().then(() => {
                tSuccess('ออกจากระบบแล้ว')
                ctx.setUser(null)
            })
        })
    }

    // ----- MEMO

    const changeNoFont = () => {
        setNoFont((prev: boolean) => {
            !prev ? document.body.classList.remove('-use-font') : document.body.classList.add('-use-font')
            localStorage.setItem('OBI_NO_FONT', !prev ? '1' : '')
            return !prev
        })
    }

    // ----- MEMO

    const { P, subsection, Menus, style } = useMemo(() => {
        if (pathname.startsWith('/setting'))
            return { P: pathname + '/', subsection: true, Menus: SettingMenus, style: '-setting' }
        if (pathname.startsWith('/admin'))
            return { P: pathname + '/', subsection: true, Menus: AdminMenus, style: '-admin' }

        return { P: pathname + '/', subsection: false, Menus: MainMenus, style: '' }
    }, [pathname])

    useEffect(() => {
        const f = document.body.classList.contains('-admin')
        if (f !== pathname.startsWith('/admin')) {
            if (f) document.body.classList.remove('-admin')
            else document.body.classList.add('-admin')
        }
    }, [pathname])

    useEffect(() => {
        localStorage.setItem('OBI_SIDEMENU_MINI', miniSideMenu ? '1' : '')
    }, [miniSideMenu])

    // ----- RENDER

    return <div className={clsNames('layout-sidemenu', miniSideMenu && '-mini', style)}>
        <div className="layout-mainmenu">
            <div className="_container">
                <Icon name={'bars'} className="-menu"/>
                <Icon href={'/home'} name="home-heart" className="mr-auto" button onClick={e => linkClick(e, history)}/>
                <Icon name="user" className="mr-1" button onClick={() => setProfile(true)}/>
                {user && user.admin && <Icon href={'/admin/home'} name="tools" className="mr-1" button onClick={e => linkClick(e, history)}/>}
                <Icon name={'sign-out'} button onClick={userLogout}/>
            </div>
        </div>
        <div className="layout-menuifo">
            <div className="_container">
                <span className={'_version'}>Version: {process.env.REACT_APP_VERSION} ~ {version}</span>
                <span className={'_user'}>{user && user.name}</span>
            </div>
        </div>

        <div className="layout-menu">
            <PageScroll container>
                {Menus.filter(m => !m.role || user.allow(m.role))
                    .map((item: any) => {
                        if (miniSideMenu) {
                            return <Tooltip key={item.page} title={item.name} position={'r'} noArrow>
                                <a href={item.url} onClick={e => linkClick(e, history)}
                                   className={clsNames('_menu', (P.startsWith(item.url + '/') || (item.matchUrl && P.startsWith(item.matchUrl))) && '-active')}>
                                    <Icon name={item.icon}/>
                                </a>
                            </Tooltip>
                        }

                        return <a key={item.page} href={item.url} onClick={e => linkClick(e, history)}
                                  className={clsNames('_menu', (P.startsWith(item.url + '/') || (item.matchUrl && P.startsWith(item.matchUrl))) && '-active')}>
                            <Icon name={item.icon}/>
                            <span className="_text">{item.name}</span>
                        </a>
                    })}
            </PageScroll>

            {subsection
                ? <a className="_menu" href={'/home'} onClick={e => linkClick(e, history)}>
                    <Icon name="arrow-left"/>
                    <span className="_text">กลับ</span>
                </a>
                : <a className="_menu" href={'/setting'} onClick={e => linkClick(e, history)}>
                    <Icon name="cog"/>
                    <span className="_text">ตั้งค่าระบบ</span>
                </a>}

            <div className="layout-option">
                <div className="_icon">
                    <Icon button name={'text'} onClick={changeNoFont} solid={noFont}/>
                </div>
                <NotifyPop/>
            </div>
            <div className="btn-coll"><Icon button name={'chevron-double-' + (miniSideMenu ? 'right' : 'left')} onClick={() => setMiniSideMenu(prev => !prev)}/></div>
        </div>
        <UserForm user={user} open={profile} onSave={() => setProfile(false)} onClose={() => setProfile(false)}/>
    </div>
}

function UserForm (props: any) {
    const [user, setuser] = useState<any>(null)
    const [employee, setEmployee] = useState<any>(null)

    const [passwordNew, setPasswordNew] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [password, setPassword] = useState('')

    // ---- ACTION

    const loadData = () => {
        setPasswordNew('')
        setPasswordConfirm('')
        setPassword('')

        get('app/profile').then(({ ok, employee, user }) => {
            if (ok) {
                setuser(user)
                setEmployee(employee)
            }
            else setEmployee(null)
        })
    }

    const saveData = (c: any) => {
        post('app/profile_save', { passwordNew, passwordConfirm, password, employee, user }).then(d => {
            if (d.ok) {
                tSuccess('บันทึกข้อมูลสำเร็จ')
                props.onSave()
            }
            else dError(d)
        }).finally(c)
    }

    // ----- EVENT

    const onChangeEmp = (update: any) => setEmployee({ ...employee, ...update })

    const onChangeUser = (update: any) => setuser({ ...user, ...update })

    // ----- RENDER

    return <Modal title={'ข้อมูลส่วนตัว'} lg noCloseBackdrop open={props.open} onClose={props.onClose} onOpenEnd={loadData} footerSave={saveData}>
        {!!user && <>
            <FormContainer label={'ข้อมูลหลัก'} flex>
                <Photo label={'Avatar'} className={'mr-3'} tag="person" value={user.attachs || []} onChange={v => onChangeUser({ attachs: v })}/>
                <Input label="ชื่อ" className="w-64 mb-auto" value={user.fullname} onChange={v => onChangeUser({ fullname: v })}/>
            </FormContainer>
        </>}

        {employee && <>
            <FormContainer label={'ข้อมูลหลัก'}>
                <div className="flex">
                    <Photo label={'ภาพพนักงาน'} classBox={'mr-3'} tag="person" value={employee.photos || ''} onChange={v => onChangeEmp({ photos: v })}/>
                    <Photo label={'บัตรประชาชน'} classBox={'mr-3'} tag="idcard" value={employee.photos || ''} onChange={v => onChangeEmp({ photos: v })}/>
                </div>
                <div className="row no-boxmargin">
                    <Input label={'ชื่อเล่น'} classBox="col w-2/12" center defValue={employee.name} key={'iname_' + employee.name} onBlur={v => onChangeEmp({ name: v })}/>
                    <Input label={'ชื่อ-สกุล'} classBox="col flex-1" defValue={employee.name_s} key={'iname_s_' + employee.name_s} onBlur={v => onChangeEmp({ name_s: v })}/>
                </div>
            </FormContainer>
            <ContactForm2 onChange={onChangeEmp} data={employee}/>
            <TableBank datas={employee.banks} onChange={(v: any) => onChangeEmp({ banks: v })}/>
        </>}

        <FormContainer label={'แก้ไขรหัสผ่าน'} flex>
            <Input label="รหัสผ่านใหม่" className="w-52" note={'หากไม่ต้องการแก้ไข ไม่ต้องกรอก'} value={passwordNew} onChange={v => setPasswordNew(v)}/>
            <Input label="ยืนยันรหัสผ่านใหม่" className="w-52 ml-4" value={passwordConfirm} onChange={v => setPasswordConfirm(v)}/>
        </FormContainer>
        <FormContainer label={'ยืนยันข้อมูล'}>
            <Input label="รหัสผ่านเดิม" type="password" className="w-52" value={password} onChange={v => setPassword(v)}/>
        </FormContainer>
    </Modal>
}

function NotifyPop () {
    const history = useHistory()

    const [notifyMute, setNotifyMute] = useState(false)
    const [notifyAlert, setNotifyAlert] = useState(false)
    const [datas, setDatas] = useState<any>([])

    // ----- ACTION

    const loadData = () => {
        get('app/notify').then((d: any) => {
            if (d.ok) {
                setDatas(d.datas)
            }
        })
    }

    // ----- EVENT

    const onGo = (url: string) => {
        history.push(url)
    }

    const onMute = (check?: any) => {
        sendEvent('notify_mute', { set: check })
        setNotifyMute(check)
    }

    const onToggle = (show?: any) => {
        if (show) {
            loadData()
            setNotifyAlert(false)
        }
    }

    // ----- MEMO

    useEffect(() => {
        addEvent('notify_user', () => {
            setNotifyAlert(true)
        })
        setNotifyMute(localStorage.getItem('OBI_NOTIFY_MUTE') === '1')
    }, [])

    return <Dropdown up className={'notify-popup'} button={<div className="btn-notify">
        {notifyAlert && <AlertPing className="_alert"/>}
        <Icon button name={notifyMute ? 'bell-slash' : 'bell'}/>
    </div>} onToggle={onToggle}>
        <Checkbox className="ml-2" m0 text={'ปิดการแจ้งเตือน (เสียง,ป้าย)'} checked={notifyMute} onChange={onMute}/>
        {datas.length > 0 ? <PageScroll container className="notify-list">
            {datas.map((d: any, x: number) => <div key={'i' + x} className={'notify-item'} onClick={() => onGo(d.url)}>
                <div className={'_text'}>{d.text}</div>
                <div className={'_time'}>{date(d.time, 'ST')} ({fromNow(d.time)})</div>
            </div>)}
        </PageScroll> : <div className="notify-empty">ไม่มีการแจ้งเตือน</div>}
    </Dropdown>
}
