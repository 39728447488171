import React, { useEffect, useState } from 'react'

import { date, get } from 'unno-comutils'
import List, { ListBody, ListButton, ListContainer, ListHead } from 'unno-comutils/List'

import { FormContainer } from 'unno-comutils/form/Form'
import { Checkbox, Input, Select } from 'unno-comutils/form'

import { PROVINCEs } from '../service/var'
import { DEFAULT_ADDRESS } from '../service/var.default'

export const ContactForm = ({ data, onChange }: any) => {
    return <>
        <h5 className={'mt-4 mb-0'}>ข้อมูลที่อยู่</h5>
        <ContactAddress onChange={(val: any) => onChange({ addressHome: val })} data={data.addressHome}/>

        <h5 className={'mt-4 mb-0'}>ข้อมูลการติดต่อ</h5>

        <div className="row">
            <Input label={'เบอร์โทร'} defValue={data.contactTel} classBox="col flex-1" key={'ctel_' + data.contactTel} onBlur={v => onChange({ contactTel: v })}/>
            <Input label={'Facebook'} defValue={data.contactFacebook} classBox="col flex-1" key={'cfb_' + data.contactFacebook} onBlur={v => onChange({ contactFacebook: v })}/>
            <Input label={'LINE ID'} defValue={data.contactLine} classBox="col flex-1" key={'cline_' + data.contactLine} onBlur={v => onChange({ contactLine: v })}/>
            <Input label={'อีเมล'} defValue={data.contactEmail} classBox="col flex-1" key={'cemail_' + data.contactEmail} onBlur={v => onChange({ contactEmail: v })}/>
        </div>

        <div className="flex">
            <Checkbox label={'ที่อยู่จัดส่งเอกสาร'} text={'เหมือนที่อยู่ปัจจุบัน'} checked={data.addressSendmail === 'home'}
                      onChange={() => onChange({ addressSendmail: data.addressSendmail === 'home' ? (data.addressHome ? data.addressHome : { ...DEFAULT_ADDRESS }) : 'home' })}/>
        </div>
        {data.addressSendmail !== 'home' && <ContactAddress onChange={(val: any) => onChange({ addressSendmail: val })} data={data.addressSendmail}/>}
    </>
}

export const ContactForm2 = ({ data, onChange, ...props }: any) => {
    let contactData = data?.contactData || {}
    let address = contactData?.address || {}
    let addressSend = contactData?.addressSend || {}

    return <>
        <FormContainer label={'ที่อยู่ตามบัตรประชาชน'}>
            <ContactAddress2 slim={props.slim} value={address} onChange={(address: any) => {onChange({ contactData: { ...contactData, address } })}}/>
        </FormContainer>

        <FormContainer label={'ข้อมูลการติดต่อ'}>
            <div className="row">
                <Input label={'เบอร์โทร'} defValue={contactData.tel || ''} classBox="col w-6/12 _input-tel" key={'ctel_' + contactData.tel}
                       onBlur={v => onChange({ contactData: { ...contactData, tel: v } })}/>
                <Input label={'อีเมล'} defValue={contactData.email || ''} classBox="col flex-1 _input-email" key={'cemail_' + contactData.email}
                       onBlur={v => onChange({ contactData: { ...contactData, email: v } })}/>
            </div>
        </FormContainer>

        <FormContainer label={'ที่อยู่จัดส่งเอกสาร'}>
            <Checkbox text={'เหมือนที่อยู่ตามบัตรประชาชน'} className="mb-4" checked={addressSend === 'address'}
                      onChange={() => onChange({ contactData: { ...contactData, addressSend: (addressSend === 'address' ? '' : 'address') } })}/>
            {addressSend !== 'address' &&
                <ContactAddress2 slim={props.slim} value={addressSend} onChange={(addressSend: any) => {onChange({ contactData: { ...contactData, addressSend } })}}/>}
        </FormContainer>
    </>
}

export const ContactAddress = (props: any) => {
    let address = props.data || { ...DEFAULT_ADDRESS }

    const onChange = (update: any) => {
        let _address = { ...address, ...update }
        props.onChange(_address)
    }

    return <>
        <div className="row">
            <Input label={'ชื่ออาคาร/หมู่บ้าน'} classBox="col w-2/12" defValue={address.name || ''} key={'aname_' + address.name} onBlur={v => onChange({ name: v })}/>
            <Input label={'ห้องเลขที่'} classBox="col w-1/12" defValue={address.room || ''} key={'aroom_' + address.room} onBlur={v => onChange({ room: v })}/>
            <Input label={'ชั้นที่'} classBox="col w-1/12" defValue={address.floor || ''} key={'afloor_' + address.floor} onBlur={v => onChange({ floor: v })}/>
            <Input label={'เลขที่'} classBox="col w-2/12" defValue={address.number || ''} key={'anumber_' + address.number} onBlur={v => onChange({ number: v })}/>
            <Input label={'หมู่ที่'} classBox="col w-1/12" defValue={address.moo || ''} key={`input_address_moo_${address && address.moo}`} onBlur={v => onChange({ moo: v })}/>
            <Input label={'ตรอก/ซอย'} classBox="col w-2/12" defValue={address.soi || ''} key={'asoi_' + address.soi} onBlur={v => onChange({ soi: v })}/>
            <Input label={'ถนน'} classBox="col w-3/12" defValue={address.road || ''} key={'aroad_' + address.road} onBlur={v => onChange({ road: v })}/>
        </div>

        <div className="row">
            <Input label={'ตำบล/แขวง'} classBox="col w-3/12" defValue={address.subdistrict || ''} key={'asubdist_' + address.subdistrict}
                   onBlur={v => onChange({ subdistrict: v })}/>
            <Input label={'อำเภอ/เขต'} classBox="col w-4/12" defValue={address.district || ''} key={'adistrict_' + address.district} onBlur={v => onChange({ district: v })}/>
            <Select label={'จังหวัด'} classBox="col w-3/12" options={PROVINCEs} value={address.province || ''} onChange={v => onChange({ province: v })}/>
            <Input label={'รหัสไปรษณีย์'} classBox="col w-2/12" defValue={address.zipcode || ''} key={'azipcode_' + address.zipcode} onBlur={v => onChange({ zipcode: v })}/>
        </div>
    </>
}

export const ContactAddress2 = (props: any) => {
    const { slim } = props
    const address = props.value || {}
    const { name, moo, soi, road } = address
    const onChange = (update: any) => props.onChange && props.onChange({ ...address, ...update })

    const isBkk = address?.province?.includes('กรุงเทพ')
    const prefix = (v: any, p: any) => v && p + v
    const location = [prefix(address.subdistrict, isBkk ? 'แขวง' : 'ตำบล'),
        prefix(address.district, isBkk ? 'เขต' : 'อำเภอ'),
        prefix(address.province, isBkk ? '' : 'จังหวัด'),
        address.zipcode].filter(Boolean).join(' ')

    return <>
        {slim && <Input key={'aname_' + name} label={'หมู่บ้าน, ห้อง/ชั้น/เลขที่'} defValue={name || ''} onBlur={v => onChange({ name: v })}/>}
        <div className="row address-row">
            {!slim && <Input key={'aname_' + name} label={'หมู่บ้าน, ห้อง/ชั้น/เลขที่'} classBox="col flex-1 _input-name" defValue={name || ''}
                             onBlur={v => onChange({ name: v })}/>}
            <Input key={'amoo_' + moo} label={'หมู่ที่'} classBox={'col w-' + (slim ? 32 : 20)} center defValue={moo || ''} onBlur={v => onChange({ moo: v })}/>
            <Input key={'asoi_' + soi} label={'ตรอก/ซอย'} classBox={'col _input-soi ' + (slim ? 'flex-1' : 'w-3/12')} defValue={soi || ''} onBlur={v => onChange({ soi: v })}/>
            <Input key={'aroad_' + road} label={'ถนน'} classBox={'col  _input-road ' + (slim ? 'flex-1' : 'w-3/12')} defValue={road || ''} onBlur={v => onChange({ road: v })}/>
        </div>
        <Select url={'app/address'} highlight value={location && { id: -1, name: location }}
                onChange={v => onChange({ subdistrict: v && v.subdistrict, district: v && v.district, province: v && v.province, zipcode: v && v.zipcode })}/>
    </>
}
